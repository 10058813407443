.full-width {
    width: 100% !important;
}

.copyable-textarea {
    width: 100%;
    position: relative;
}
.copyable-textarea textarea {
    width: 100%;
    resize: vertical;
    min-height: 3em;
    overflow: hidden;

    border: none;
    background-color: transparent;
    padding: 0.5rem;
    font-family: serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
}