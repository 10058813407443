.home-container {
    text-align: center;
}

.hero-banner {
    background-color: #1a73e8;
    color: #fff;
    padding: 50px 0;
}

.intro-content {
    padding: 50px 15px;
}

.start-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: #1a73e8;
    text-decoration: none;
    border-radius: 5px;
}
.start-btn:hover {
    background-color: #155dc4;
}
